// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDaFkOAqqFwcv7VW5gDf1REXcj5IjvJ9Wg",
    authDomain: "denov-seo.firebaseapp.com",
    projectId: "denov-seo",
    storageBucket: "denov-seo.appspot.com",
    messagingSenderId: "49146179928",
    appId: "1:49146179928:web:73018a4c5a322a70a5f52d"
  };

// const firebaseConfig = {
//   apiKey: "AIzaSyAqqCTNj-RZcvqDRAN1xo5nMhwl8h_hQO8",
//   authDomain: "kasb-hunar-maktabi.firebaseapp.com",
//   projectId: "kasb-hunar-maktabi",
//   storageBucket: "kasb-hunar-maktabi.appspot.com",
//   messagingSenderId: "616695050204",
//   appId: "1:616695050204:web:a6b87a584d949945ef6919",
//   measurementId: "G-5LG0T6CG0B"
// };
// const storage = getStorage()
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const newsColRef = collection(db, "news");
const teachersColRef = collection(db, "teachers");
const statusColRef = collection(db, "students");

export default {  statusColRef, teachersColRef, newsColRef };