<template>
  <div class="top">
    <div class="container">
      <div class="items">
				<!-- Item-1 -->
        <div class="item">
          <div class="img"><img src="../assets/clock.svg" alt="Clock" width="30"/></div>
          <div class="text">
            <div class="title">Dushanba-Shanba</div>
						<div class="subtitle">8:00-18:00</div>
          </div>
        </div>
				<!-- Item-2 -->
        <div class="item">
          <div class="img"><img src="../assets/phone.svg" alt="Phone" width="30"/></div>
          <div class="text">
						<div class="title">Telefon raqam</div>
						<a  href="tel:+998764149160" class="subtitle">+998 76 414 91 60</a>
          </div>
        </div>
				<!-- Item-3 -->
        <div class="item">
          <div class="img"><img src="../assets/email.svg" alt="Email" width="30" /></div>
          <div class="text">
						<div class="title">Elektron pochta</div>
						<div class="subtitle">Denovseovajsb@mail.ru</div>
          </div>
        </div>
				<!-- Item-4 -->
        <div class="item">
					<a class="socially" href="https://facebook.com/Denov tuman SES/"><img src="../assets/FacebookOrg.svg"></a>
					<a class="socially" href="https://t.me/DenovSEOvaJSB"><img src="../assets/TelegramOrg.svg"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a {
	text-decoration: none;
	color: #fff;
}

.top {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: #42649D;

  .container {
    width: 1100px;
    margin: 0 auto;

    .items {
			display: flex;
			align-items: center;
			justify-content: space-between;
			// gap: 110px;

      .item {
				display: flex;
				// justify-content: center;
				align-items: center;
				gap: 10px;

        .img {
          width: 35px;
        }

				.socially {
					img {
						width: 30px;

						&:hover {
							scale: 1.1;
						}
					}
				}

        
				.text {
					color: #fff;
					text-align: center;

					.title {
						font-size: 14px;
						font-weight: 500;
					}
					.subtitle {
						font-size: 11px;
						font-weight: 300;
					}
				}
      }
    }
  }
}
</style>