<template>
  <TheTop></TheTop>
  <TheNavbar></TheNavbar>
  <router-view/>
  <TheFooter></TheFooter>
</template>

<script>
import TheNavbar from './components/TheNavbar.vue';
import TheFooter from './components/TheFooter.vue';
import TheTop from './components/TheTop.vue';
  export default {
    name: 'Vue',
    components: {
    TheNavbar,TheTop, TheFooter
}
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    // font-family: 'Dancing Script', cursive;
    // border: 1px solid red;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  .container {
    // min-width: 700px;
    // width: 1150px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: content-box;
  }
  
  a {
    text-decoration: none;
  }
  </style>
  
