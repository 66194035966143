<template>
  <header>
      <div class="container">
          <div class="left">
              <router-link class="logo" to="/">
                <img src="../assets/logo-brend.png" alt="">
                <div class="logo-text">
                  Surxondaryo viloyati Denov tumani
                  Sanitariya-epidemiologik osoyishtalik
                  va jamoat salomatligi bo’limi
                </div>
              </router-link>
          </div>
          <div class="center">
              <ul v-show="!mobile" class="navigation" v-if="!isLoggedIn">
                <li><router-link class="link" to="/">Bosh sahifa</router-link></li>
                <li><router-link class="link" to="/news">Yangiliklar</router-link></li>
                <li><router-link class="link" to="/direktor">Rahbariyat</router-link></li>
                <li><router-link class="link" to="/about">Biz haqimizda</router-link></li>
                <li><router-link class="link" to="/contact">Bog'lanish</router-link></li>
              </ul>
              <ul v-show="!mobile" class="navigation" v-else>
                <li><router-link class="link" to="/">Bosh sahifa</router-link></li>
                <li><router-link class="link" to="/news">Yangiliklar</router-link></li>
                <li><router-link class="link" to="/about">Biz haqimizda</router-link></li>
                <li><router-link class="link" to="/contact">Bog'lanish</router-link></li>
              </ul>
          </div>
          <div class="right">
            <!-- <i @click="showSearch()" v-if="!search" class="fas fa-search"></i>
            <div class="xmark" @click="showSearch()" v-else >
              <div class="line"></div>
              <div class="line"></div>
            </div> -->
              <!-- <div class="search" v-show="search">
                  <input type="text" placeholder="Qidirish" >
                  <i class="fas fa-search"></i>
              </div> -->
              <div class="login" v-if="!isLoggedIn">
                  <router-link class="login-link" to="/login">
                    <i class="fas fa-user"></i>
                  </router-link> 
              </div>
              <div class="signout" @click="handleSignOut" v-else>
                <i class="fas fa-sign-out-alt"></i>
              </div>
              <div class="humburger">
                  <i @click="toggleMobileNav()" v-show="mobile" v-if="!false" class="far fa-bars" :class=" { 'icon-active' : mobileNav } "></i>
                  <!-- <i @click="toggleMobileNav()" v-else class="fa-solid fa-xmark" :class=" { 'icon-active' : mobileNav }"></i> -->
              </div>
          </div>
          <transition name="mobile-nav">
            <ul v-show="mobileNav" class="dropdown-nav">
              <li><router-link class="link" to="/">Bosh sahifa</router-link></li>
                <li><router-link class="link" to="/news">Yangiliklar</router-link></li>
                <li><router-link class="link" to="/direktor">Rahbariyat</router-link></li>
                <li><router-link class="link" to="/about">Biz haqimizda</router-link></li>
                <li><router-link class="link" to="/contact">Bog'lanish</router-link></li>
          </ul>
      </transition>
      </div>
  </header>
</template>

<script>
import { ref } from 'vue';
import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth';
import router from '@/router';

export default {
name: "TheNavbar",
data() {
  return {
    isLoggedIn: ref(false),
    auth: null,
    mobile: null,
    mobileNav: null,
    windowWith: null,
    humburger: false,
    search: null,
  };
},
mounted() {
  this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      if (user){
        this.isLoggedIn = true;
      }
      else {
        this.isLoggedIn = false;
      }
      // localStorage.isLoggedIn = this.isLoggedIn
      localStorage.isLoggedIn = this.isLoggedIn
    });
},
created() {
  window.addEventListener("resize", this.checkScreen);
  this.checkScreen();
},
methods: {
  handleSignOut () {
      signOut(this.auth).then(() => {
        router.push("/")
      });
    },
  showSearch() {
    this.search = !this.search;
  },
  toggleMobileNav() {
    this.mobileNav = !this.mobileNav;
    this.humburger = !this.humburger;
  },
  checkScreen() {
    this.windowWith = window.innerWidth;
    if (this.windowWith < 1100) {
      this.mobile = true;
    } else {
      this.mobile = false;
      this.mobileNav = false;
    }
  },
},
};
</script>

<style lang="scss" scoped>

header {
background: #f3f3fb;

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  margin: 0 auto;

  @media (max-width: 1500px) {
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 950px;
  }

  @media (max-width: 1000px) {
    width: 800px;
  }

  @media (max-width: 850px) {
    width: 600px;
  }

  @media (max-width: 650px) {
    width: 400px;
  }

  @media (max-width: 450px) {
    width: 300px;
  }

  .left {
    padding: 10px 0;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 50px;
      }

      .logo-text {
        width: 200px;
        margin-left: 10px;
        font-size: 10px;
        color:#111f50c0;
        font-weight: 500;
      }
    }
  }

  .center {
    transition: .5s ease all;
    .navigation {
      display: flex;
      align-items: center;
      gap: 30px;
      transition: .5s ease all;  
      list-style-type: none;

      .link {
        padding: 10px 0;
        font-size: 14px;
        font-weight: 400;
        color: #42649D;
        transition: .3s ease all;
        border-bottom:1px solid transparent;
        &:hover {
          border-color: #42649D;
          color: #42649D;
        }
      }

      .link.router-link-active {
        border-color: #42649D;
        color: #42649D;
      }
    }
  }

  .right {
    position: relative;
    transition: .5s ease all;
    display: flex;
    align-items: center;
    gap: 20px;

    i {
      padding: 3px;
      cursor: pointer;
      font-size: 18px;
      color: #42649D;
    }

    .xmark {
        cursor: pointer;
        padding: 15px 30px;
        padding-left: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .line {
          position: absolute;
          width: 18px;
          border-radius: 10px;
          height: 3px;
          background: #42649D;
          transition: .5s ease all;

          &:nth-child(1) {
          transform: rotate(45deg);
          }

          &:nth-child(2) {
          transform: rotate(-45deg);
          }
        }
      }

    .search {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 1s ease all;
      top: 60px;
      right: 60px;

      input {
        width: 250px;
        border-radius: 20px;
        padding: 5px 10px;
        border-color: #42649D;

        &::placeholder {
          padding-left: 5px;
        }
      }
    }
  }
  .dropdown-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9;
    width: 100%;
    max-width: 250px;
    height: 100%;
    background-color: #F3F3FB;
    top: 118px;
    gap: 20px;
    left: 0;
    box-shadow: #111f5054 0px 7px 29px 0px;

    li {
      margin-left: 0;
      padding-left: 20px;
      

      .link {
        color: #42649D;
      }
    }
  }

  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: all .5s ease;
  }

  .mobile-nav-enter-from,
  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }

  .mobile-nav-enter-to {
    transform: translateX(0);
  }
}
}
</style>