<template>
  <footer>
    <div class="container">
      <div class="f-logo">
        <img src="../assets/logo-brend.png" alt="" />
      </div>
      <div class="f-pages">
        <ul>
          <li>Sahifalar</li>
          <li><router-link class="link" to="/">Bosh sahifa</router-link></li>
          <li>
            <router-link class="link" to="/news">Yangiliklar</router-link>
          </li>
          <li><router-link class="link" to="/news">Rahbariyat</router-link></li>
          <li>
            <router-link class="link" to="/news">Biz haqimizda</router-link>
          </li>
          <li>
            <router-link class="link" to="/contact">Bog'lanish</router-link>
          </li>
        </ul>
      </div>
      <div class="f-social">
        <ul>
          <li>Ijtimoiy tarmoqlar</li>
          <li>
            <a href="https://facebook.com/Denov tuman SES/">
              <div class="logo-fb"></div>
              <span>Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://t.me/DenovSEOvaJSB">
              <div class="logo-tg"></div>
              Telegram
            </a>
          </li>
        </ul>
      </div>
      <div class="f-location">
        <ul>
          <li>Bizning manzil</li>
          <li>
            <a href="#">
				Surxondaryo viloyati, Denov tumani, 
                S.Otaboev vodiy maxallasi, Oltin vodiy 
                ko’cha, 45-uy
            </a>
          </li>
          <li>
            <a href="tel:+998764149160">
              <div class="logo-tel"></div>
              +998 76 414 91 60
            </a>
          </li>
          <li>
            <a href="#">
              <div class="logo-mail"></div>
              Denovseovajsb@mail.ru
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <div class="brand">
    © Surxondaryo viloyati Denov tumani Sanitariya-epidemiologik osoyishtalik va
    jamoat salomatligi bo’limi - {{ new Date().getFullYear() }}.
  </div>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style lang="scss" scoped>
.brand {
  width: 100%;
  text-align: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42649D;
  // opacity: 0.65;
  background: #f3f3fb;
  padding-bottom: 20px;
}

footer {
  width: 100%;
  height: 325px;
  background: #f3f3fb;
  display: flex;
  // flex-direction: column;

  .container {
    min-width: 700px;
    width: 1150px;
    align-self: center;

    display: flex;
    justify-content: space-between;

    .f-logo {
      width: 160px;
      height: 160px;
      align-self: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .f-pages {
      ul {
        list-style-type: none;

        li {
          font-style: normal;
          font-weight: 400; //Poppins
          font-size: 14px; //Poppins
          line-height: 20px;
          opacity: 0.75;
          margin-bottom: 5px;

          // font-weight: 700;
          // font-size: 18px;

          a {
            color: #42649D;
          }
        }

        li:hover {
          opacity: 1;
        }

        li:first-child {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: #42649D;
          opacity: 0.75;
        }

        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    .f-social {
      ul {
        list-style-type: none;

        li {
          font-style: normal;
          font-weight: 400; //Poppins
          font-size: 14px; //Poppins
          line-height: 20px;
          opacity: 0.75;
          margin-bottom: 8px;

          // font-weight: 700;
          // font-size: 18px;

          a {
            color: #42649D;
            display: flex;
            // cursor: default;

            .logo-fb {
              width: 21px;
              height: 20px;
              background: url(../assets/fb.png) center / cover no-repeat;
              margin-right: 15px;
              cursor: pointer;
            }

            .logo-tg {
              width: 21px;
              height: 20px;
              background: url(../assets/telegram.png) center / cover no-repeat;
              margin-right: 15px;
              cursor: pointer !important;
            }

            span {
              cursor: pointer;
            }
          }
        }

        li:hover {
          opacity: 1;
        }

        li:first-child {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: #42649D;
          opacity: 0.75;
        }

        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    .f-location {
      max-width: 320px;

      ul {
        list-style-type: none;

        li {
          font-style: normal;
          font-weight: 400; //Poppins
          font-size: 14px; //Poppins
          line-height: 20px;
          opacity: 0.75;
          margin-bottom: 15px;

          // font-weight: 700;
          // font-size: 18px;

          a {
            color: #42649D;
            display: flex;
            align-items: center;
            // cursor: default;

            .logo-tel {
              width: 16px;
              height: 16px;
              background: url(../assets/tel.png) center / cover no-repeat;
              margin-right: 15px;
              cursor: pointer;
            }

            .logo-mail {
              width: 18px;
              height: 16px;
              background: url(../assets/mail.png) center / cover no-repeat;
              margin-right: 15px;
              cursor: pointer !important;
            }

            span {
              cursor: pointer;
            }
          }
        }

        li:hover {
          opacity: 1;
        }

        li:first-child {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          color: #42649D;
          opacity: 0.75;
        }

        li:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>